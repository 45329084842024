import React from 'react';
import { merge, omit, without } from 'lodash';

import { UX2, constants } from '@wsb/guac-widget-core';
import * as utils from '../../../common/Themes/Default/Dials/Colors/utils';
import Default from '../../../common/Themes/Default/Theme';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import { levelFilter } from '../../../common/utils/text';
import { sectionHrTypes, WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';
import { mergeTypographyOverrides } from '../../../common/utils/typography';
import themeConfig from '../themeConfig';

const { NONE } = sectionHrTypes;
const { renderModes, buttons } = constants;

// The Minimal theme left aligns content heading and text.  Add a widget layout to this array for center alignment:
const widgetsWithCenteredText = ['about1', 'content4', 'introduction4', 'payment1', 'payment2'];

export const widgetsWithImageBorder = [
  'about1',
  'about2',
  'about3',
  'about7',
  'about8',
  'about9',
  'about10',
  'content1',
  'content2',
  'content6',
  'content7',
  'header9',
  'menu3',
  'payment1'
];

const imagesWithoutBorder = [
  'PAYMENT_CREDIT_CARD_BADGES_RENDERED',
  'PAYMENT_PAYPALBUTTON_IMAGE_RENDERED',
  'HEADER_LOGO_IMAGE_RENDERED'
];

const mediaBorderStyle = {
  borderColor: 'highlight',
  borderStyle: 'solid',
  borderWidth: 'xlarge'
};

const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highContrast',
    fontSize: 'xxlarge',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme20 extends Default {
  static config = themeConfig;

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        phoneOnSecondaryPage: true,
        useCropBackground: true,
        useAddress: true,
        useBigLogo: true,
        headerTreatmentsConfig: {},
        coverImagePivot: {},
        showAddressDefault: true
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(!widgetsWithCenteredText.includes(widgetPreset) && { alignmentOption: 'left' })
    };
  }

  static excludedProps = without(Default.excludedProps, 'address');

  static get displayName() {
    return 'Theme20';
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      borderColorHighlight: this.highlight,
      backgroundColorHighlight: this.highlight,
      backgroundColorAction: this.backgroundColorAction,
      colorActionHover: this.colorActionHover,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          ...typographyShared1
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: '0.107em',
            textTransform: 'uppercase'
          }
        },
        HeadingDelta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: '0.107em',
            textTransform: 'uppercase'
          }
        },
        HeadingEpsilon: props => ({
          ...mergeTypographyOverrides.call(this, 'BodyBeta', props)
        }),
        HeadingZeta: props => ({
          ...mergeTypographyOverrides.call(this, 'DetailsGamma', props)
        }),
        DetailsGamma: {
          style: {
            font: 'alternate',
            color: 'lowContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.107em',
            textTransform: 'uppercase',
            lineHeight: '1.5'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.107em',
            textTransform: 'uppercase'
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.107em',
            textTransform: 'uppercase'
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: props => {
          const { renderMode } = this.base;
          const { size = 'default', fill = this.getButtonDefaults().fill } = props;
          const sizes = {
            small: {
              fontSize: 'xsmall'
            },
            default: {
              fontSize: 'small'
            },
            large: {
              fontSize: 'small'
            }
          };
          const ghostOverride =
            fill !== buttons.fills.GHOST
              ? {}
              : {
                ...(renderMode !== renderModes.EDIT && {
                  [':hover']: {
                    color: 'actionHover'
                  }
                })
              };
          return {
            style: merge(
              {
                font: 'alternate',
                fontWeight: 'bold',
                letterSpacing: '1px',
                textTransform: 'uppercase',
                ...sizes[size]
              },
              ghostOverride
            )
          };
        },
        InputAlpha: props => {
          return merge(mergeTypographyOverrides.call(this, 'BodyAlpha', props), {
            style: {
              color: 'input',
              ['@xs-only']: {
                fontSize: 'medium' // 16px
              }
            }
          });
        }
      }
    };
  }

  highlight = () => {
    const [primary, neutral, background] = utils.getDial(this, [
      'primary',
      'neutral',
      'background'
    ]);

    return background === primary ? neutral : primary;
  };

  backgroundColorAction = () => {
    const [primary, accent, neutral, highlight, background, multiplier] = utils.getDial(this, [
      'primary',
      'accent',
      'neutral',
      'highlight',
      'background',
      'multiplier'
    ]);

    return background === primary
      ? neutral
      : background === accent
        ? background.setAlpha(100).contrast(50 * multiplier)
        : highlight.contrastWith(background, 10 * multiplier);
  };

  colorActionHover = () => {
    const multiplier = utils.getDial(this, 'multiplier');
    const buttonBackground = this.mappedValues.backgroundColorActionHover.call(this);
    return buttonBackground.setAlpha(100).contrast(90 * multiplier);
  };

  Section({ children, skipDivider, ...props }) {
    const footerStyles =
      this.base.widgetType === 'FOOTER'
        ? { paddingTop: this.base.widgetPreset === 'footer4' ? 'xlarge' : '0' }
        : {};

    return super.Section(
      this.merge(
        {
          style: {
            'paddingVertical': 'xlarge',
            '@xs-only': {
              paddingVertical: 'large'
            },
            ...footerStyles
          },
          children:
            this.base.widgetType === 'FOOTER' &&
            !skipDivider &&
            this.base.category === 'neutral' ? (
                <React.Fragment>
                  <UX2.Element.Divider style={{ paddingVertical: 'medium' }} />
                  { children }
                </React.Fragment>
              ) : (
                children
              )
        },
        props
      )
    );
  }

  Hero(props) {
    const newStyle = omit(props.style, ['> :nth-child(n)', ' > :last-child']); // remove spacingVertical styles from Minimal
    return super.Hero(Object.assign(props, { style: newStyle }));
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          style: {
            width: '100%',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            marginTop: 'xsmall'
          },
          typography: 'HeadingEpsilon',
          featured: true
        },
        props
      )
    );
  }

  SectionHeading({ children, layout = 'full', richtext, ...props }) {
    const pseudoStyles = {
      content: `""`,
      height: '1px',
      marginTop: '-1px',
      background: 'currentColor',
      position: 'absolute',
      top: '.75em',
      width: '100vw',
      display: 'block'
    };
    const childStyles = {
      'textTransform': 'unset',
      'letterSpacing': 'unset',
      'display': 'inline-block',
      'position': 'relative',
      'color': 'inherit',
      'maxWidth': '80%',
      ':before': {
        right: '100%',
        marginRight: 'medium',
        ...pseudoStyles
      },
      ':after': {
        left: '100%',
        marginLeft: 'medium',
        ...pseudoStyles
      }
    };
    return super.SectionHeading(
      this.merge(
        {
          children: (
            <UX2.Element.Element style={ childStyles } richtext={ richtext }>
              { children }
            </UX2.Element.Element>
          ),
          style: {
            // Static
            'overflow': 'hidden',
            'textAlign': 'center',
            '@md': {
              textAlign: layout === 'full' ? 'center' : 'left'
            },
            '@xs-only': {
              marginBottom: 'medium'
            }
          },
          typography: 'HeadingBeta',
          sectionHeadingHR: NONE
        },
        props
      )
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  ContentBasic(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    return super.ContentBasic(this.merge(overrides, props));
  }

  ContentCard(props) {
    const overrides =
      widgetsWithCenteredText.indexOf(this.base.widgetPreset) === -1 ? { alignment: 'left' } : {};
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(overrides, styles, props));
  }

  ContentBigText(props) {
    return super.ContentBigText(
      this.merge(
        {
          typography: 'BodyBeta'
        },
        props
      )
    );
  }

  Image({ isContentCard, ...props }) {
    const { widgetPreset } = this.base;
    const hasBorder =
      widgetsWithImageBorder.includes(widgetPreset) &&
      imagesWithoutBorder.indexOf(props['data-aid']) === -1;
    const style = hasBorder ? mediaBorderStyle : {};
    return super.Image(
      this.merge({ style, mobileGutterWidth: hasBorder ? (isContentCard ? 16 : 20) : 24 }, props)
    );
  }

  ImageThumbnail(props) {
    return super.ImageThumbnail(this.merge({ mobileGutterWidth: 24 }, props));
  }

  ImageLogo(props) {
    return super.ImageLogo(
      this.merge(
        {
          style: {
            border: 'none',
            backgroundColor: 'section'
          }
        },
        props
      )
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...modernIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  Button(props) {
    const { fill = this.getButtonDefaults().fill } = props;
    const { renderMode } = this.base;

    const noHoverState = renderMode && renderMode === renderModes.EDIT;
    const hoverStyles = noHoverState
      ? {}
      : {
        overflow: 'hidden',
        transition: 'all .3s ease',
        position: 'relative',
        zIndex: 0,
        ['.btnAddon']: {
          content: '""',
          backgroundColor: 'actionHover',
          position: 'absolute',
          width: 0,
          height: '120%',
          bottom: '-2px',
          left: '-large',
          transform: 'skewX(15deg)',
          transition: 'all .3s ease',
          zIndex: -1
        }
      };

    const ghost =
      fill !== buttons.fills.GHOST
        ? {}
        : {
          btnAddon: true,
          style: {
            textDecoration: 'none',
            borderWidth: 'xsmall',
            ...hoverStyles,
            ...(!noHoverState && {
              [':hover']: {
                backgroundColor: 'actionHover',
                ['.btnAddon']: {
                  width: '120%'
                }
              }
            })
          }
        };

    return super.Button(this.merge(ghost, props));
  }

  Heading(props) {
    const { tag, level } = props;
    const { widgetType, widgetPreset } = this.base;
    const typographyMapping = {
      5: 'DetailsAlpha'
    };

    return super.Heading(
      this.merge(
        {
          typography: typographyMapping[levelFilter({ tag, level })],
          style: getMenuHeadingStyle(tag, widgetType, widgetPreset)
        },
        props
      )
    );
  }

  CardBannerHeading(props) {
    return this.HeadingMajor(props);
  }

  FeaturedHeading(props) {
    return super.FeaturedHeading(
      this.merge(
        {
          typography: 'HeadingZeta'
        },
        props
      )
    );
  }

  FeaturedText(props) {
    return super.FeaturedText(
      this.merge(
        {
          typography: 'HeadingBeta'
        },
        props
      )
    );
  }

  Input(props) {
    return super.Input(
      this.merge(
        {
          style: {
            borderColor: 'input',
            borderWidth: 'xsmall',
            borderRadius: 'none',
            paddingVertical: 'small',
            paddingHorizontal: 'small',
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  InputSelect(props) {
    return super.InputSelect(
      this.merge(
        {
          style: {
            // Theme Based
            borderWidth: 'xsmall',
            borderRadius: 'medium',
            // Static
            borderStyle: 'solid',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingVertical: 'small',
            paddingHorizontal: 'small',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(
      this.merge(
        {
          style: {
            top: '33%'
          }
        },
        props
      )
    );
  }

  Link(props) {
    const style = this.base.widgetType === 'FOOTER' ? { fontSize: 'small' } : {};
    return super.Link(
      this.merge(
        {
          style
        },
        props
      )
    );
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            opacity: 0.4
          }
        },
        props
      )
    );
  }

  NavMenuLink(props) {
    return super.NavMenuLink(
      this.merge(
        {
          typography: 'NavAlpha'
        },
        props
      )
    );
  }

  MobileNavLink(props) {
    return super.MobileNavLink(
      this.merge(
        {
          typography: 'NavBeta',
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 'medium',
            paddingVertical: 'small'
          }
        },
        props
      )
    );
  }

  NavigationDrawerLink(props) {
    return this.MobileNavLink(props);
  }

  // New Typography Mapping according to Sticker Sheet

  HeadingMajor(props) {
    return super.HeadingMajor(
      this.merge(
        {
          typography: 'HeadingAlpha'
        },
        props
      )
    );
  }

  DisplayHeading(props) {
    return super.DisplayHeading(
      this.merge(
        {
          typography: 'HeadingBeta'
        },
        props
      )
    );
  }

  NavVerticalHeading(props) {
    return super.NavVerticalHeading(
      this.merge(
        {
          typography: 'HeadingGamma'
        },
        props
      )
    );
  }

  HeadingMinor(props) {
    return super.HeadingMinor(
      this.merge(
        {
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  ContentHeading(props) {
    // legacy code
    const headingStyles = {
      fontSize: 'medium',
      textTransform: 'uppercase',
      letterSpacing: 1
    };
    // legacy code
    const style = Number(props.level) !== 2 && props.tag !== 'h2' ? headingStyles : {};

    return super.ContentHeading(
      this.merge(
        {
          style,
          typography: 'HeadingDelta'
        },
        props
      )
    );
  }

  HeroText({ alignmentOption, ...props }) {
    return super.SubTagline(
      this.merge(
        {
          style: {
            lineHeight: '1.25',
            marginBottom: 'small',
            ['@md']: {
              marginTop: alignmentOption === 'center' ? 'none' : 'small'
            }
          },
          typography: 'BodyAlpha'
        },
        props
      )
    );
  }

  Phone(props) {
    return super.Phone(
      this.merge(
        {
          typography: 'BodyAlpha',
          featured: false
        },
        props
      )
    );
  }

  ContactBarPhone(props) {
    return super.ContactBarPhone(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  ContactBarAddress(props) {
    return super.ContactBarAddress(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  ContactBarPipe(props) {
    return super.ContactBarPipe(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  PromoBanner(props) {
    return super.PromoBanner(
      this.merge(
        {
          style: {
            paddingVertical: 'xsmall'
          }
        },
        props
      )
    );
  }

  PromoBannerText(props) {
    return super.PromoBannerText(
      this.merge(
        {
          style: {
            '@xs-only': {
              fontSize: 'small'
            }
          }
        },
        props
      )
    );
  }

  EmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.EmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.EmbedVideo(props);
  }

  WrapperInsetEmbedVideo({ isVerticalVideo, ...props }) {
    if (isVerticalVideo) {
      return super.WrapperInsetEmbedVideo(this.merge({ style: { maxHeight: '680px' } }, props));
    }
    return super.WrapperInsetEmbedVideo(props);
  }
}

export default Theme20;
